import {
  initialize as ldClientInitialize,
  LDClient,
  LDFlagChangeset,
  LDFlagSet,
  LDMultiKindContext,
  LDOptions,
} from 'launchdarkly-js-client-sdk'
import { AllFlagsLDClient } from 'launchdarkly-react-client-sdk'

export const initLDClient = async (
  clientSideID: string,
  multiContext: LDMultiKindContext,
  options?: LDOptions,
  targetFlags?: LDFlagSet
): Promise<AllFlagsLDClient> => {
  const allOptions: LDOptions = {
    wrapperName: 'react-client-sdk-gamma',
    sendEventsOnlyForVariation: true,
    ...options,
  }
  const ldClient = ldClientInitialize(clientSideID, multiContext, allOptions)

  return new Promise<AllFlagsLDClient>((resolve) => {
    ldClient.on('ready', () => {
      const flags = fetchFlags(ldClient, targetFlags)
      resolve({ flags, ldClient })
    })
  })
}

/**
 * Retrieves flag values.
 *
 * @param ldClient LaunchDarkly client
 * @param reactOptions Initialization options for the LaunchDarkly React SDK
 * @param targetFlags If specified, `launchdarkly-react-client-sdk` will only request and listen to these flags.
 *
 * @returns an `LDFlagSet` with the current flag values from LaunchDarkly filtered by `targetFlags`.
 */
export const fetchFlags = (ldClient: LDClient, targetFlags?: LDFlagSet) => {
  let rawFlags: LDFlagSet = {}

  if (targetFlags) {
    for (const flag in targetFlags) {
      rawFlags[flag] = ldClient.variation(flag, targetFlags[flag])
    }
  } else {
    rawFlags = ldClient.allFlags()
  }
  return rawFlags
}

/**
 * Gets the flags to pass to the provider from the changeset.
 *
 * @param changes the `LDFlagChangeset` from the ldClient onchange handler.
 * @param targetFlags if targetFlags are specified, changes to other flags are ignored and not returned in the
 * flattened `LDFlagSet`
 * @param reactOptions reactOptions.useCamelCaseFlagKeys determines whether to change the flag keys to camelCase
 * @return an `LDFlagSet` with the current flag values from the LDFlagChangeset filtered by `targetFlags`. The returned
 * object may be empty `{}` if none of the targetFlags were changed.
 */
export const getFlattenedFlagsFromChangeset = (
  changes: LDFlagChangeset,
  targetFlags: LDFlagSet | undefined
): LDFlagSet => {
  const flattened: LDFlagSet = {}
  for (const key in changes) {
    if (!targetFlags || targetFlags[key] !== undefined) {
      // tslint:disable-next-line:no-unsafe-any
      const flagKey = key
      flattened[flagKey] = changes[key].current
    }
  }

  return flattened
}
